
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Login from "./pages/Login";
import Negociar from "./pages/Negociar";
import Carta from "./pages/Carta";


function RoutesApp(){
    return(
        <BrowserRouter>
            <Routes>
                <Route path="/" element={ <Login/> } />
                <Route path="/negociar" element={ <Negociar/> } />
                <Route path="/negociar/carta" element={ <Carta/> } />
            </Routes>
        </BrowserRouter>
    )
}

export default RoutesApp;

