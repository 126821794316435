import React, { useEffect } from 'react';
import './style.css';
import logo from '../../images/Serasa.png';
import { useLocation } from 'react-router-dom'; // Import necessário

function Carta() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search); // Obtém a query string
    const cpfFromUrl = queryParams.get('cpf'); // Obtém o CPF da URL

    const { nome } = location.state || {}; // Recebe o nome do state
    const primeiroNome = nome ? nome.split(' ')[0] : ''; // Pega o primeiro nome
    
    useEffect(() => {
        // Rola para o topo quando a página é carregada
        window.scrollTo(0, 0);
    }, []); // Dependência vazia para que esse efeito seja executado apenas uma vez após a montagem

    return (
        <>
            <header className="header">
                <div className="header-left">
                    <img src={logo} alt="Logo" className="logo" />
                    <div className="header-name">
                        <h1 className="name">Olá, {primeiroNome}.</h1>
                        <p className="cpf">CPF: {cpfFromUrl}</p>
                    </div>
                </div>
                <div className="header-right">
                    <i className="fas fa-chevron-down chevron-icon"></i>
                </div>
            </header>

            <div className="iframe-container">
                <iframe
                    src={`https://tb.agenciadpx.com.br/carta-de-quitacao?cpf=${cpfFromUrl}`} // Passa o CPF como parâmetro na URL do iframe
                    title="Serasa"
                    className="iframe"
                ></iframe>
            </div>
        </>
    );
}

export default Carta;
